import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class CategoryV3 extends Component {
    render() {
        let publicUrl = process.env.PUBLIC_URL + '/';

        const propertyTypes = [
            { name: 'Casas', link: '/properties?type=casas' },
            { name: 'Apartamentos', link: '/properties?type=apartamentos' },
            { name: 'Terrenos', link: '/properties?type=terrenos' },
            { name: 'Hoteles', link: '/properties?type=hoteles' },
            { name: 'Villas', link: '/properties?type=villas' },
        ];

        return (
            <div className="ltn__banner-area pt-120 go-top">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title-area ltn__section-title-2--- text-center">
                                <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">Propiedades</h6>
                                <h1 className="section-title">Categorías</h1>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {propertyTypes.map((propertyType, index) => (
                            <div className="col-lg-4 col-md-6" key={index}>
                                <Link to={propertyType.link}>
                                    <div className="ltn__banner-item ltn__banner-style-4 text-color-white bg-image" data-bs-bg={publicUrl + `assets/img/personal/img-${propertyType.name.toLowerCase()}-01.webp`}>
                                        <div className="ltn__banner-info">
                                            <h3>{propertyType.name}</h3>
                                            <p>Ver propiedades disponibles</p>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    }
}

export default CategoryV3;
