import React, { Component } from 'react';

class Social extends Component {

    render() {

    return <div className="ltn__social-media">
			<ul>
				<li><a href="https://www.facebook.com/profile.php?id=61555152942642&mibextid=2JQ9oc" title="Facebook"><i className="fab fa-facebook-f" /></a></li>
				<li><a href="https://www.instagram.com/finanviviendas/" title="Instagram"><i className="fab fa-instagram" /></a></li>
			</ul>
		</div>
        }
}

export default Social