import React from 'react';
import Navbar from './global-components/navbar-v2';
import PageHeader from './global-components/page-header';
import Footer from './global-components/footer';
import {useLocation} from "react-router-dom/cjs/react-router-dom";
import CallToActonV1 from "./section-components/call-to-action-v1";
import Properties from "./shop-components/properties";

const Product_List = () => {
    // OBTENER URL
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const type = searchParams.get('type');
    return <div>
        <Navbar/>
        <PageHeader headertitle={type}/>
        <Properties/>
        <CallToActonV1
            title="¿Necesitas más información?"
            message="Comunícate con nosotros"
            buttonText="WhatsApp"
            whatsappMessage={`Hola, quisiera más información`}
        />
        <Footer/>
    </div>
}

export default Product_List

