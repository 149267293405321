import React, { useState } from 'react';
import ReactImageLightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // Esto es importante para los estilos predeterminados

const ImageGallery = ({ images }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  // Asumimos que 'images' contiene rutas relativas desde la carpeta 'public'
  // Aquí ajustamos cada ruta de imagen para incluir 'process.env.PUBLIC_URL'
  const adjustedImages = images.map(image => process.env.PUBLIC_URL + '/' + image);

  return (
    <div>
      <div className="row">
        {adjustedImages.map((image, index) => (
          <div className="col-md-6 mt-3" key={index}>
            <img
              src={image}
              onClick={() => {
                setPhotoIndex(index);
                setIsOpen(true);
              }}
              alt={`Gallery ${index}`}
              style={{ width: '100%', height: '300px', cursor: 'pointer' }}
            />
          </div>
        ))}
      </div>

      {isOpen && (
        <ReactImageLightbox
          mainSrc={adjustedImages[photoIndex]}
          nextSrc={adjustedImages[(photoIndex + 1) % adjustedImages.length]}
          prevSrc={adjustedImages[(photoIndex + adjustedImages.length - 1) % adjustedImages.length]}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() => setPhotoIndex((photoIndex + adjustedImages.length - 1) % adjustedImages.length)}
          onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % adjustedImages.length)}
        />
      )}
    </div>
  );
};

export default ImageGallery;
