const properties = [
    {
        "PropertyID": "B01",
        "ImagePath": "/assets/img/personal/gaudi/entry.png",
        "Status": "En Venta",
        "buildStatus": "Pre-venta",
        "propertyName": "Portonaíto Barú Nativo",
        "noDetails":"yes",
        "amount": 45,
        "amenidades":["Gym","Zona BBQ","Garage","Piscina","Salon de juegos","Zona recreativa","Cancha de futbol"],
        "City": "Cartagena",
        "Country": "Bolívar",
        "meters1": "200 m² - 700 m²",
        "PropertyType": "Terreno",
        "profileImg": "assets/img/personal/brouche/portada.png",
        "images":[
            "assets/img/personal/brouche/img01.jpg",
            "assets/img/personal/brouche/img02.jpg",
            "assets/img/personal/brouche/img03.jpg",
            "assets/img/personal/brouche/img04.jpg"],
        "amenidadesImg":[
            "assets/img/personal/brouche/ames/ame05.webp",
            "assets/img/personal/brouche/ames/ame02.webp",
            "assets/img/personal/brouche/ames/ame03.webp",
            "assets/img/personal/brouche/ames/ame04.webp",],
        "pdf": "assets/img/personal/brouche/pdfBro.pdf",
        "mapsUrl": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15708.66435320617!2d-75.67441495165227!3d10.16715158914963!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e5887aa3c1b1d33%3A0x14ef5ac3fdda0952!2sPlaya%20de%20la%20Pen%C3%ADnsula%20de%20Bar%C3%BA!5e0!3m2!1ses-419!2sco!4v1708533110771!5m2!1ses-419!2sco",
        "detalles": "Portonaito Barú Nativo va más allá de ser un simple proyecto inmobiliario; es una oportunidad única " +
            "para asegurar tu futuro financiero. Con una ubicación privilegiada en la hermosa península de Barú, y la creciente " +
            "popularidad de esta joya caribeña entre los turistas, este proyecto se presenta como el sueño perfecto para los " +
            "inversores inmobiliarios. Aquí, puedes construir la villa de tus sueños en un lugar anhelado para todos, lejos del " +
            "   estrés de la vida cotidiana y rodeado por la impresionante belleza natural del Caribe colombiano .",
    },
    {
        "PropertyID": "B02",
        "ImagePath": "/assets/img/personal/gaudi/entry.png",
        "Status": "En Venta",
        "buildStatus": "Pre-venta",
        "propertyName": "Villas de Gaudí",
        "noDetails":"yes",
        "amount": 18,
        "amenidades":["Gym","Zona BBQ","Garage","Piscina","Salon de juegos","Zona recreativa","Cancha de futbol"],
        "City": "Barú, Cartagena",
        "Country": "Bolívar",
        "meters1": "300 m² - 431 m²",
        "PropertyType": "Terreno",
        "profileImg": "assets/img/personal/gaudi/entry2.webp",
        "images":[
            "assets/img/personal/gaudi/1.webp",
            "assets/img/personal/gaudi/2.webp",
            "assets/img/personal/gaudi/3.webp",
            "assets/img/personal/gaudi/4.webp"],
        "pdf": "assets/img/personal/gaudi/gaudi.pdf",
        "mapsUrl": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15708.66435320617!2d-75.67441495165227!3d10.16715158914963!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e5887aa3c1b1d33%3A0x14ef5ac3fdda0952!2sPlaya%20de%20la%20Pen%C3%ADnsula%20de%20Bar%C3%BA!5e0!3m2!1ses-419!2sco!4v1708533110771!5m2!1ses-419!2sco",
        "detalles": "¡Tener tu propio paraíso en Barú ya es posible! Ubicando  en isla de Barú a orillas de carretera, " +
            "con acceso a playa, a tan solo 35 minutos del centro histórico de Cartagena, rodeado de bosques tropicales y aves exóticas. " +
            "Villas de Gaudí es un proyecto exclusivo con  18 lotes urbanizables para vivienda de tipo residencial, " +
            "lo cual permite que sea un lugar de descanso y refugio, así como un proyecto lleno de viviendas de lujo, con vías adoquinadas." +
            "Cada casa será diseñada de forma personalizada de acuerdo a los deseos de cada cliente, creando un condominio " +
            "de piezas arquitectónicas de más del más alto nivel.",
    },
    {
        "PropertyID": "B03",
        "Status": "En Venta",
        "buildStatus": "Pre-venta",
        "propertyName": "Prados de Pontevedra",
        "noDetails":"yes",
        "amount": 5,
        "amenidades":["Gym","Zona BBQ","Garage","Piscina","Salon de juegos","Zona recreativa","Cancha de futbol"],
        "City": "Turbaco",
        "Country": "Bolívar",
        "meters1": "300 m² - 431 m²",
        "PropertyType": "Terreno",
        "profileImg": "assets/img/personal/pontevedra/entry.webp",
        "images":[
            "assets/img/personal/pontevedra/1.webp",
            "assets/img/personal/pontevedra/2.webp",
            "assets/img/personal/pontevedra/3.webp",
            "assets/img/personal/pontevedra/4.webp"],
        "pdf": "assets/img/personal/gaudi/gaudi.pdf",
        "mapsUrl": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7851.55929145951!2d-75.37918670642091!3d10.279330800000002!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e589f55640b2a41%3A0x7d80f9d123f3b4ba!2sPrados%20de%20Pontevedra!5e0!3m2!1ses!2sco!4v1708542586366!5m2!1ses!2sco",
        "detalles": "Ubicación perfecta en la doble calzada entre Turbaco y Arjona, a solo 20 minutos de Cartagena," +
            " ofreciendo un paraíso natural cerca de la ciudad. Disponible, lotes de 405m² para construir la casa de tus " +
            "sueños personalizada. La documentación está completamente actualizada con escrituración inmediata, facilitando " +
            "el inicio de tu proyecto. Más del 40% del área es verde, incluyendo piscina, parque infantil, boulevard y ciclorruta, " +
            "proporcionando un entorno natural y social de lujo. Este proyecto es único en su clase, con amplias vías y zonas de diversión, " +
            "además de una futura zona comercial en la entrada, seguridad las 24 horas, 7 días a la semana, y acceso a " +
            "todos los servicios públicos necesarios para tu nuevo hogar.",
    },
    {
        "PropertyID": "H01",
        "ImagePath": "assets/img/personal/CasaGloria/img01.webp",
        "Status": "En Venta",
        "buildStatus": "Pre-venta",
        "propertyName": "Boutique Casa Gloria",
        "amount": 1,
        "amenidades":["Gym","Zona BBQ","Garage","Piscina","Salon de juegos","Zona recreativa", "Jacuzzi"],
        "City": "Cartagena",
        "Country": "Bolívar",
        "Price": "Solicite más información",
        "Bedrooms": "1",
        "Bathrooms": "1",
        "meters1": "? m²",
        "PropertyType": "Hotel",
        "profileImg": "assets/img/personal/CasaGloria/port.webp",
        "images":[
            "assets/img/personal/CasaGloria/1.webp",
            "assets/img/personal/CasaGloria/2.webp",
            "assets/img/personal/CasaGloria/3.webp",
            "assets/img/personal/CasaGloria/4.webp"],
        "mapsUrl": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3923.9356503548256!2d-75.54839092426668!3d10.426681265546067!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8ef625d66978a4fb%3A0xf5b6b12626a6d6!2sHotel%20Casa%20Gloria!5e0!3m2!1ses!2sco!4v1708549470707!5m2!1ses!2sco",
        "detalles": "Está ubicado en un Sexto Piso en el edificio Santa Cruz de Castillo Grande,  frente al Parque del Club de " +
            "Oficiales Navales con Balcón que tiene 180° con Vista al mar, 105 metros de Construcción, Aire Acondicionado Central,  " +
            "Parqueadero Privado, Dos Alcobas, Dos Baños, Cuarto de Servicio con Baño Independiente, Zona de Labores,  " +
            "Cocina Integral,  Sala Comedor con Mueble Bar y Mueble de TV",
    },
    {
        "PropertyID": "A01",
        "Status": "En Venta",
        "buildStatus": "Pre-venta",
        "propertyName": "Edificio Sorrento",
        "amount": 1,
        "amenidades":["Garage","Piscina","Salon de juegos","Zona recreativa","Jacuzzi"],
        "City": "Cartagena",
        "Country": "Bolívar",
        "Price": " Solicite más información",
        "Bedrooms": "3",
        "Bathrooms": "2",
        "meters1": "105 m²",
        "PropertyType": "Apartamento",
        "profileImg": "assets/img/personal/Sorento/img01.webp",
        "images":[
            "assets/img/personal/Sorento/img02.webp",
            "assets/img/personal/Sorento/img03.webp",],
        "mapsUrl": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3923.8679492555825!2d-75.54438082426664!3d10.432051965449958!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8ef62f9a8f3e6941%3A0x7ed16852e2d8c037!2sEdificio%20Sorrento%20El%20Cabrero!5e0!3m2!1ses-419!2sco!4v1708546658147!5m2!1ses-419!2sco",
        "detalles": "El edificio se encuentra ubicado el Barrio del Cabrero este edificio de apartamentos y vivienda en la zona mas tradicional y residencial de Cartagena de indias.",
    },
    {
        "PropertyID": "A03",
        "Status": "En Arriendo",
        "buildStatus": "Pre-venta",
        "propertyName": "Cabrero Marina Club",
        "amount": 1,
        "amenidades":["Garage","Piscina","Salon de juegos","Zona recreativa","Jacuzzi"],
        "City": "Cartagena",
        "Country": "Bolívar",
        "Price": " Solicite más información",
        "Bedrooms": "1",
        "Bathrooms": "1",
        "meters1": "77 m²",
        "PropertyType": "Apartamento",
        "profileImg": "assets/img/personal/marinaClub/port.webp",
        "images":[
            "assets/img/personal/marinaClub/1.webp",
           // "assets/img/personal/marinaClub/2.webp",
            "assets/img/personal/marinaClub/3.webp",
            "assets/img/personal/marinaClub/4.webp"],
        "mapsUrl": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3923.833053476109!2d-75.53826042426658!3d10.434819165400427!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8ef62f50897cd635%3A0x8208fc76093f324c!2sEdificio%20Cabrero%20Marina%20Club!5e0!3m2!1ses-419!2sus!4v1708654660800!5m2!1ses-419!2sus       ",
        "detalles": "El edificio se encuentra ubicado el Barrio del Cabrero este edificio de apartamentos y vivienda en la " +
            "zona mas tradicional y residencial de Cartagena de indias. 4 ascensores, zona social con capacidad de más de " +
            "200 personas en ambiente con aire acondicionado y exterior, sauna, turco, jacuzzis, piscina de mayores, de niños, " +
            "asoleadero, zona de barbacoa amplia, teatrino, área de gimnasio, vista 360 grados en zona social del último piso,amplio lobby",
    },
    {
        "PropertyID": "C01",
        "ImagePath": "assets/img/personal/lazuli/img01.jpg",
        "Status": "En Venta",
        "buildStatus": "Pre-venta",
        "propertyName": "Tierra Bomba - Lazuli",
        "noDetails":"yes",
        "amount": 4,
        "amenidades":["Gym","Zona BBQ","Garage","Piscina","Salon de juegos","Zona recreativa"],
        "City": "Cartagena",
        "Country": "Bolívar",
        "Price": "Solicite más información",
        "Bedrooms": "2",
        "Bathrooms": "2",
        "meters1": "300 m²",
        "meters6": "431,35 m²",
        "PropertyType": "Casa",
        "profileImg": "assets/img/personal/lazuli/4.webp",
        "images":[
            "assets/img/personal/lazuli/1.webp",
            "assets/img/personal/lazuli/2.webp",
            "assets/img/personal/lazuli/3.webp",
            "assets/img/personal/lazuli/4.webp"],
        "pdf": "assets/img/personal/lazuli/lazuli.pdf",
        "mapsUrl": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3924.641828523386!2d-75.58079682426704!3d10.370496466548573!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8ef629b05f9bcd93%3A0x2971d02fc2e7c020!2sL%C3%A1zuli%20Beach%20Houses!5e0!3m2!1ses-419!2sus!4v1708654425212!5m2!1ses-419!2sus",
        "detalles": "Exclusivo Condominio de playa en la isla de Tierra Bomba, disfruta de tener una cabaña en una isla " +
            "y disfrutar del Mar Caribe, Lotes de libre construcción a 7 minutos de Cartagena con vista al mar y a la " +
            "ciudad de Cartagena, condominio cerrado con Portería y seguridad 24hs",
    },
    {
        "PropertyID": "C02",
        "Status": "En Venta",
        "buildStatus": "Pre-venta",
        "propertyName": "Casa Barcelona de indias",
        "amount": 1,
        "amenidades":["Garage","Piscina","Salon de juegos","Zona recreativa","Jacuzzi"],
        "City": "Cartagena",
        "Country": "Bolívar",
        "Price": " Solicite más información",
        "Bedrooms": "4",
        "Bathrooms": "2",
        "meters1": "900 m²",
        "PropertyType": "Casa",
        "profileImg": "assets/img/personal/casaBarcelona/port.webp",
        "images":[
            "assets/img/personal/casaBarcelona/1.webp",
            "assets/img/personal/casaBarcelona/2.webp",
            "assets/img/personal/casaBarcelona/3.webp",
            "assets/img/personal/casaBarcelona/4.webp"],
        "mapsUrl": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d37320.975572925076!2d-75.45995323968901!3d10.509776554785361!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8ef63bbaf660bab3%3A0x7e4c3af277f99b9!2sConjunto%20Residencial%20Barcelona%20de%20Indias!5e0!3m2!1ses!2sco!4v1708549646941!5m2!1ses!2sco",
        "detalles": "La propiedad es una casa espaciosa con aproximadamente 300 metros cuadrados de construcción, " +
            "ubicada en una esquina con un lote de 900 metros cuadrados. Este hogar cuenta con cuatro alcobas, cada una " +
            "con su propio baño, y ofrece una terraza en el segundo piso además de otra terraza en la planta baja, ideal " +
            "para el disfrute al aire libre. Incluye también un área de labores y cuarto de servicio, lo que proporciona " +
            "funcionalidad y comodidad. La casa se sitúa frente a un lago en la urbanización Barcelona de Indias, en la zona norte, " +
            "destacándose por su excelente ubicación y vistas privilegiadas.",
    },
    {
        "PropertyID": "H03",
        "Status": "En Arriendo",
        "buildStatus": "Pre-venta",
        "propertyName": "Boutique San Diego",
        "amount": 1,
        "amenidades":["Garage","Piscina","Salon de juegos","Zona recreativa","Jacuzzi"],
        "City": "Cartagena",
        "Country": "Bolívar",
        "Price": " Solicite más información",
        "Bedrooms": "1",
        "Bathrooms": "1",
        "meters1": "? m²",
        "PropertyType": "Hotel",
        "profileImg": "assets/img/personal/SanDiego/port.webp",
        "images":[
            "assets/img/personal/SanDiego/1.webp",
            "assets/img/personal/SanDiego/2.webp",
            "assets/img/personal/SanDiego/3.webp",
            "assets/img/personal/SanDiego/4.webp"],
        "mapsUrl": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3923.9184283553395!2d-75.55172291581073!3d10.428047738983498!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8ef62f98de842615%3A0x6fe01a7709e33406!2sSan%20Diego%2C%20Cartagena%20de%20Indias%2C%20Provincia%20de%20Cartagena%2C%20Bol%C3%ADvar!5e0!3m2!1ses!2sco!4v1708551485022!5m2!1ses!2sco",
        "detalles": "El Hotel Boutique Callecitas de San Diego en Cartagena de Indias, Bolívar, ofrece una experiencia de" +
            " 5 estrellas a menos de un kilómetro de Playa Marbella y a 1.9 km de Playa de Bocagrande. Destaca por sus servicios " +
            "como deportes acuáticos, wifi gratuito, aire acondicionado y baño privado en todas las habitaciones, además de " +
            "comodidades como cafetera, ropa de cama y toallas. Los huéspedes pueden disfrutar de un bar, servicio de habitaciones y " +
            "una recepción abierta las 24 horas. El hotel también es un punto de partida ideal para actividades como la pesca. Atracciones " +
            "cercanas incluyen el Palacio de la Inquisición, el Parque Bolívar y el Museo del Oro de Cartagena. Para la comodidad de sus clientes, " +
            "el hotel ofrece un servicio de traslado de pago al Aeropuerto Internacional Rafael Núñez, que se encuentra a 7 km.",
    },
    {
        "PropertyID": "A02",
        "ImagePath": "assets/img/personal/Sorento/img01.jpg",
        "Status": "En Venta",
        "buildStatus": "Construido",
        "propertyName": "Apartamento Santa Cruz",
        "amount": 1,
        "amenidades":["Garage","Piscina","Salon de juegos","Zona recreativa",],
        "City": "Cartagena",
        "Country": "Bolívar",
        "Price": "530.000.000",
        "Bedrooms": "2",
        "Bathrooms": "2",
        "meters1": "105 m²",
        "PropertyType": "Apartamento",
        "profileImg": "assets/img/personal/SantaCruz/port.webp",
        "images":[
            "assets/img/personal/SantaCruz/1.webp",
            "assets/img/personal/SantaCruz/2.webp",
            "assets/img/personal/SantaCruz/3.webp",
            "assets/img/personal/SantaCruz/4.webp",],
        "mapsUrl": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1649.995720698758!2d-75.54824006913555!3d10.391896351907707!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8ef62f4ebcb2a721%3A0x6dc428069b076494!2sEdificio%20Santa%20Cruz!5e0!3m2!1ses!2sco!4v1708552096888!5m2!1ses!2sco",
        "detalles": "Este apartamento está situado en un sexto piso del edificio Santa Cruz de Castillo Grande, " +
            "frente al Parque del Club de Oficiales Navales. Cuenta con un balcón de 180° ofreciendo vistas al mar y " +
            "una superficie de 105 metros cuadrados. El apartamento dispone de aire acondicionado central, parqueadero " +
            "privado, dos alcobas, dos baños, y adicionalmente, cuarto de servicio con baño independiente. También incluye " +
            "una zona de labores, cocina integral y una sala comedor equipada con mueble bar y mueble para TV.",
    },
    {
        "PropertyID": "B04",
        "Status": "En Venta",
        "buildStatus": "Lote",
        "propertyName": "Lote Cabrero",
        "noDetails":"yes",
        "amount": 1,
        "City": "Cartagena",
        "Country": "Bolívar",
        "Price": "2.400.000.000",
        "meters1": "424 m²",
        "PropertyType": "Terreno",
        "profileImg": "assets/img/personal/LoteCabrero/port.webp",
        "mapsUrl": "https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d245.24112974450904!2d-75.54294802910165!3d10.43283498521007!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1ses!2sco!4v1708554718091!5m2!1ses!2sco",
        "detalles": "Ubicado en el prestigioso sector de El Cabrero, este lote frente al mar se extiende sobre 424 " +
            "metros cuadrados y viene con las bases y columnas ya construidas, diseñadas específicamente para un hotel. " +
            "El proyecto cuenta con las licencias requeridas para la construcción y operación de un establecimiento hotelero, " +
            "ofreciendo una oportunidad única para desarrollar un negocio en una ubicación inmejorable con vistas panorámicas al " +
            "mar. Ideal para inversores que buscan crear un destino de hospedaje de lujo, aprovechando la demanda de alojamientos " +
            "frente a la costa y la popularidad turística de la zona.",
    },
];

export default properties;
