import React from 'react';
import Navbar from './global-components/navbar-v2';
import PageHeader from './global-components/page-header';
import Footer from './global-components/footer';
import ShopCasaGloria from "./shop-components/shop-casaGloria";

const Product_Details = () => {
    return <div>
        <Navbar />
        <PageHeader headertitle="Detalles" customclass="mb-0" />
        <ShopCasaGloria />
        <Footer />
    </div>
}

export default Product_Details

