import React from 'react';
import Navbar from './global-components/navbar-v2';
import Banner from './section-components/banner-v4';
import Category from './section-components/category-v3';
import Service from './section-components/service-v2';
import Footer from './global-components/footer';
import CallToActonV1 from "./section-components/call-to-action-v1";

const Home_V1 = () => {
    return <div>
        <Navbar/>
        <Banner/>
        <Category/>
        <Service/>
        <CallToActonV1
            title="¿Necesitas más información?"
            message="Comunícate con nosotros"
            buttonText="WhatsApp"
            whatsappMessage={`Hola, necesito mas información`}
        />
        <Footer/>
    </div>
}

export default Home_V1

