import React, {useEffect, useState} from 'react';
import {useParams, Link} from 'react-router-dom';
import {CgGym} from "react-icons/cg";
import {MdOutdoorGrill, MdOutlineGarage, MdOutlinePool, MdSportsSoccer} from "react-icons/md";
import {IoGameController} from "react-icons/io5";
import propertiesData from '../utils/propertiesData';
import { FaTree} from "react-icons/fa";
import 'react-image-lightbox/style.css';
import ImageGallery from "../global-components/galleryComponent";
import {LiaHotTubSolid} from "react-icons/lia";
import CallToActonV1 from "../section-components/call-to-action-v1";

const ShopCasaGloria = () => {
    const {propertyId} = useParams();
    const [property, setProperty] = useState(null);
    const imagesAme = property?.amenidadesImg || [];
    const imagesGallery = property?.images || [];
    // Diccionario de componentes de íconos
    const amenityIcons = {
        "Gym": CgGym,
        "Zona BBQ": MdOutlineGarage,
        "Garage": MdOutdoorGrill,
        "Piscina": MdOutlinePool,
        "Salon de juegos": IoGameController,
        "Zona recreativa": FaTree,
        "Cancha de futbol": MdSportsSoccer,
        "Jacuzzi": LiaHotTubSolid,
    };


    useEffect(() => {
        const prop = propertiesData.find(p => p.PropertyID.toString() === propertyId);
        setProperty(prop);
    }, [propertyId]);

    let publicUrl = process.env.PUBLIC_URL + '/';

    if (!property) {
        return <div>Cargando...</div>;
    }

    return (
        <div className="ltn__shop-details-area pb-10 mt-5">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-12">
                        <div className="ltn__shop-details-inner ltn__page-details-inner mb-60">
                            <div className="ltn__blog-meta">
                                <ul>
                                    <li className="ltn__blog-category">
                                        <Link to="#">{property.buildStatus}</Link>
                                    </li>
                                    <li className="ltn__blog-category">
                                        <Link className="bg-orange" to="#">{property.Status}</Link>
                                    </li>
                                    <li className="ltn__blog-date">
                                        <i className="far fa-calendar-alt"/>Feb 03, 2024
                                    </li>
                                </ul>
                            </div>
                            <h1>{property.propertyName}</h1>
                            <label><span className="ltn__secondary-color"><i
                                className="flaticon-pin"/></span> {property.City}, {property.Country}</label>
                            <h4 className="title-2">Descripción</h4>
                            <p>{property.detalles}
                            </p>
                            <h4 className="title-2">Detalles</h4>
                            <div className="property-detail-info-list section-bg-1 clearfix mb-60">
                                <ul>
                                    <li><label>ID producto:</label> <span>{property.PropertyID}</span></li>
                                    <li><label>Tipo:</label> <span>{property.PropertyType}</span></li>
                                </ul>
                                <ul>
                                    <li><label>Area desde:</label> <span>{property.meters1}</span>
                                    </li>
                                    <li><label>Cantidad:</label> <span>{property.amount}</span></li>
                                </ul>
                            </div>
                            {property.amenidades && (
                                <div>
                                    <h4 className="title-2">Características & amenidades</h4>
                                    <div className="property-detail-feature-list clearfix mb-45">
                                        <ul>
                                            {property.amenidades.map((amenidad, index) => {
                                                const Icon = amenityIcons[amenidad];
                                                return (
                                                    <li key={index}>
                                                        <div className="property-detail-feature-list-item">
                                                            <i className="d-flex justify-content-center align-items-center">
                                                                {Icon ? <Icon className="icon-property"/> : null}
                                                            </i>
                                                            <div>
                                                                <h6>{amenidad}</h6>
                                                            </div>
                                                        </div>
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                        <div>
                                            <ImageGallery images={imagesAme}/>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {property.pdf && (
                                <div>
                                    <h4 className="title-2">Información adicional</h4>
                                    <a href={publicUrl + property.pdf} download={property.propertyName}
                                       className="btn btn-warning rounded-pill smooth-hover fw-bold">Descargar PDF</a>
                                </div>
                            )}
                            <CallToActonV1
                                title="¿Necesitas más información?"
                                message="Comunícate con nosotros"
                                buttonText="WhatsApp"
                                whatsappMessage={`Hola, quisiera más información de la propiedad: ${property.propertyName}`}
                            />
                            {property.images && (
                                <div>
                                    <h4 className="title-2">Galería</h4>
                                    <ImageGallery images={imagesGallery}/>
                                </div>
                            )}
                            <h4 className="title-2">Ubicación</h4>
                            <div className="property-details-google-map mb-60">
                                <iframe
                                    src={property.mapsUrl}
                                    width="100%" height="100%" frameBorder={0} allowFullScreen aria-hidden="false"
                                    tabIndex={0}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <aside className="sidebar ltn__shop-sidebar ltn__right-sidebar---">
                            {/* Author Widget */}
                            <div className="widget ltn__author-widget">
                                <div className="ltn__author-widget-inner text-center">
                                    <img src={publicUrl + "assets/img/team/4.jpg"} alt="Imagen"/>
                                    <h5>Marcos Elias Flores</h5>
                                    <small>Ceo de Finanviviendas</small>
                                    <div className="product-ratting">
                                        <ul>
                                            <li><i className="fas fa-star"/></li>
                                            <li><i className="fas fa-star"/></li>
                                            <li><i className="fas fa-star"/></li>
                                            <li><i className="fas fa-star-half-alt"/></li>
                                            <li><i className="far fa-star"/></li>
                                        </ul>
                                    </div>
                                    <p>Siempre a su disposición</p>
                                </div>
                            </div>
                            {/* Tagcloud Widget */}
                            <div className="widget ltn__tagcloud-widget go-top">
                                <h4 className="ltn__widget-title ltn__widget-title-border-2">Tags</h4>
                                <ul>
                                    <li><Link to="/">Apartamentos</Link></li>
                                    <li><Link to="/">Casas</Link></li>
                                    <li><Link to="/">Lotes</Link></li>
                                    <li><Link to="/">Terrenos</Link></li>
                                    <li><Link to="/">Viviendas</Link></li>
                                    <li><Link to="/">Hogar</Link></li>
                                </ul>
                            </div>
                            {/* Banner Widget */}
                            <div className="widget ltn__banner-widget d-none go-top">
                                <Link to="/shop"><img src={publicUrl + "assets/img/banner/2.jpg"} alt="#"/></Link>
                            </div>
                        </aside>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ShopCasaGloria;
