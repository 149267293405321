import React, {Component} from 'react';

class BannerV4 extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL + '/'

        return <div className="ltn__slider-area ltn__slider-2--- ltn__slider-6 section-bg-2">
            <div className="ltn__slide-one-active slick-slide-arrow-1 slick-slide-dots-1 arrow-white">
                {/* ltn__slide-item */}
                <div
                    className="ltn__slide-item ltn__slide-item-2--- ltn__slide-item-6 text-color-white bg-overlay-theme-black-60">
                    {/* El contenedor del video de fondo */}
                    <div className="video-background">
                        <video playsInline="playsinline" autoPlay="autoplay" muted="muted" loop="loop"
                               style={{width: '100%', height: '100%', objectFit: 'cover'}}>
                            <source src={publicUrl + "assets/img/personal/portada.webm"} type="video/webm"/>
                        </video>
                    </div>

                    {/* El contenido del slide */}
                    <div className="ltn__slide-item-inner text-center">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 align-self-center">
                                    <div className="slide-item-info">
                                        <div className="slide-item-info-inner ltn__slide-animation">
                                            <div className="slide-video mb-50">
                                            </div>
                                            <h6 className="slide-sub-title white-color animated"><span><i
                                                className="fas fa-home"/></span> Finanviviendas</h6>
                                            <h1 className="slide-title text-uppercase animated ">Encuentra el lugar
                                                ideal<br/> con nosotros</h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    }
}

export default BannerV4