import React from 'react';

const CallToActonV1 = (props) => {
    const { title, message, buttonText, whatsappMessage } = props;

    const getWhatsAppLink = () => {
        const phoneNumber = "573001234567";
        const baseUrl = "https://wa.me/";
        const encodedMessage = encodeURIComponent(whatsappMessage);
        return `${baseUrl}${phoneNumber}?text=${encodedMessage}`;
    };

    return <div className="ltn__call-to-action-area call-to-action-6 mt-5">
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <div className="call-to-action-inner call-to-action-inner-6 ltn__secondary-bg position-relative text-center---">
                        <div className="coll-to-info text-color-white">
                            <h1>{title}</h1>
                            <p>{message}</p>
                        </div>
                        <div className="btn-wrapper go-top">
                            <a className="btn btn-effect-3 btn-white" href={getWhatsAppLink()} target="_blank" rel="noopener noreferrer">{buttonText} <i className="icon-next" /></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default CallToActonV1;
