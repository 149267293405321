import React from 'react';
import Navbar from './global-components/navbar-v2';
import PageHeader from './global-components/page-header';
import ContactInfo from './section-components/contact-info';
import Footer from './global-components/footer';
import CallToActonV1 from "./section-components/call-to-action-v1";

const ContactV1 = () => {
    return <div>
        <Navbar />
        <PageHeader headertitle="Contactanos" subheader="Contacto" />
        <ContactInfo />
        <CallToActonV1
            title="¿Necesitas más información?"
            message="Comunícate con nosotros"
            buttonText="WhatsApp"
            whatsappMessage={`Hola, necesito mas información`}
        />
        <Footer />
    </div>
}

export default ContactV1

