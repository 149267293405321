import React, {Component} from 'react';
import {Link} from 'react-router-dom';

class ServiceV2 extends Component {

    render() {

        return <div className="ltn__feature-area pt-90 pb-90 go-top">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="section-title-area ltn__section-title-2--- text-center">
                            <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">Características</h6>
                            <h1 className="section-title">Características principales</h1>
                        </div>
                    </div>
                </div>
                <div className="row ltn__custom-gutter justify-content-center">
                    <div className="col-lg-3 col-sm-6 col-12">
                        <div className="ltn__feature-item ltn__feature-item-6">
                            <div className="ltn__feature-icon">
                                <span><i className="flaticon-apartment"/></span>
                            </div>
                            <div className="ltn__feature-info">
                                <h4><Link to="/service-details">Construcción de calidad</Link></h4>
                                <p>Los productos que ofertamos están construidas con materiales de alta calidad</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-12">
                        <div className="ltn__feature-item ltn__feature-item-6 active">
                            <div className="ltn__feature-icon">
                                <span><i className="flaticon-park"/></span>
                            </div>
                            <div className="ltn__feature-info">
                                <h4><Link to="/service-details">ECO Friendly</Link></h4>
                                <p>Contribuimos con el medio ambiente y el uso de recursos renovables con nuestros
                                    productos</p><br/>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-12">
                        <div className="ltn__feature-item ltn__feature-item-6">
                            <div className="ltn__feature-icon">
                                <span><i className="flaticon-maps-and-location"/></span>
                            </div>
                            <div className="ltn__feature-info">
                                <h4><Link to="/service-details">Ubicación atractiva</Link></h4>
                                <p>Nuestras instalaciones se encuentran en la mejor ubicación del sector</p><br/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    }
}

export default ServiceV2