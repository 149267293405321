import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as HashRouter, Route, Switch } from "react-router-dom";

import ServiceDetails from './components/service-details';
import Error  from './components/404';

import Contact from './components/contact';
import HomeV4 from "./components/home-v4";
import casa_gloria from "./components/casa_gloria";
import ShopGrid from "./components/shop-grid";


class Root extends Component {
    render() {
        return(
                <HashRouter basename="/">
	                <div>
	                <Switch>
                        <Route exact path="/" component={HomeV4} />

                        {/*<Route path="/about" component={About} />*/}
                        <Route path="/service-details" component={ ServiceDetails } />
                        {/*<Route path="/portfolio" component={ Portfolio } />*/}
                        {/*<Route path="/portfolio-v2" component={ PortfolioV2 } />*/}
                        {/*<Route path="/team" component={ Team } />*/}
                        {/*<Route path="/team-details" component={ TeamDetails } />*/}
                        {/*<Route path="/faq" component={ Faq } />*/}
                        {/*<Route path="/coming-soon" component={ ComingSoon } />*/}
                        <Route path="/404" component={ Error } />
                        {/*<Route path="/location" component={ Location } />*/}
                        <Route path="/properties" component={ ShopGrid } />
                        {/*<Route path="/shop-left-sidebar" component={ ShopLeftSidebar } />*/}
                        {/*<Route path="/shop-right-sidebar" component={ ShopRightSidebar } />*/}

                        {/*<Route path="/villas-gaudi" component={ villasgaudi } />*/}
                        <Route path="/property-details/:propertyId" component={ casa_gloria } />
                        {/* blog */}
                        {/*<Route path="/blog-grid" component={ BlogGrid } />*/}
                        {/*<Route path="/blog-left-sidebar" component={ BlogLeftSidebar } />*/}
                        {/*<Route path="/blog-right-sidebar" component={ BlogRightSidebar } />*/}
                        {/*<Route path="/blog" component={ Blog } />*/}


                        {/*<Route path="/blog-details" component={ BlogDetails } />*/}
                        <Route path="/contact" component={ Contact } />
                        {/*<Route path="/cart" component={ Cart } />*/}
                        {/*<Route path="/checkout" component={ Checkout } />*/}
                        {/*<Route path="/my-account" component={ MyAccount } />*/}
                        {/*<Route path="/login" component={ Login } />*/}
                        {/*<Route path="/register" component={ Register } />*/}
                        {/*<Route path="/add-listing" component={ AddListing } />*/}
                        {/*<Route path="/wishlist" component={ Wishlist } />*/}
                        {/*<Route path="/order-tracking" component={ OrderTracking } />*/}
                        {/*<Route path="/history" component={ History } />*/}
	                </Switch>
	                </div>
                </HashRouter>
        )
    }
}

export default Root;

ReactDOM.render(<Root />, document.getElementById('quarter'));
