import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import Social from '../section-components/social';
import Copyright from './copyright';

class Footer_v1 extends Component {

    componentDidMount() {

        const $ = window.$;

        let publicUrl = process.env.PUBLIC_URL + '/'
        const minscript = document.createElement("script");
        minscript.async = true;
        minscript.src = publicUrl + "assets/js/main.js";

        document.body.appendChild(minscript);

        $('.go-top').find('a').on('click', function () {

            $(".quarter-overlay").fadeIn(1);

            $(window).scrollTop(0);

            setTimeout(function () {
                $(".quarter-overlay").fadeOut(300);
            }, 800);

        });


        $(document).on('click', '.theme-btn-1 ', function () {
            $('div').removeClass('modal-backdrop');
            $('div').removeClass('show');
            $('div').removeClass('fade');
            $('body').attr("style", "");
        });
    }

    render() {

        let publicUrl = process.env.PUBLIC_URL + '/'

        return (
            <footer className="ltn__footer-area  ">
                <div className="footer-top-area  section-bg-2 plr--5">
                    <div className="container-fluid">
                        <div className="row justify-content-between">
                            <div className="col-xl-3 col-md-6 col-sm-6 col-12">
                                <div className="footer-widget footer-about-widget">
                                    <div className="footer-logo">
                                        <div className="site-logo">
                                            <img src={publicUrl + "assets/img/personal/finan-nobgz.png"} alt="Logo"/>
                                            <h3 className="mt-4">Finanviviendas</h3>
                                        </div>
                                    </div>
                                    <p>Somos los mejores en lo que hacemos y nos comprometemos a brindar el mejor
                                        servicio </p>
                                    <div className="footer-address">
                                        <ul>
                                            <li>
                                                <div className="footer-address-icon">
                                                    <i className="icon-placeholder"/>
                                                </div>
                                                <div className="footer-address-info">
                                                    <p>Cartagena, Colombia</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="footer-address-icon">
                                                    <i className="icon-call"/>
                                                </div>
                                                <div className="footer-address-info">
                                                    <p><a href="tel:+573012785734">+57 301-278-5734</a></p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="footer-address-icon">
                                                    <i className="icon-mail"/>
                                                </div>
                                                <div className="footer-address-info">
                                                    <p><a href="mailto:ventas@finanviviendas.com">ventas@finanviviendas.com</a>
                                                    </p>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="ltn__social-media mt-20">
                                        <Social/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-2 col-md-6 col-sm-6 col-12">
                                <div className="footer-widget footer-menu-widget clearfix">
                                    {/*<h4 className="footer-title">Empresa</h4>*/}
                                    {/*<div className="footer-menu go-top">*/}
                                    {/*    <ul>*/}
                                    {/*        <li><Link to="/">Nosotros</Link></li>*/}
                                    {/*        <li><Link to="/">Productos</Link></li>*/}
                                    {/*        <li><Link to="/">Contacto</Link></li>*/}
                                    {/*    </ul>*/}
                                    {/*</div>*/}
                                </div>
                            </div>
                            <div className="col-xl-2 col-md-6 col-sm-6 col-12">
                                <div className="footer-widget footer-menu-widget clearfix">
                                    <h4 className="footer-title">Servicios</h4>
                                    <div className="footer-menu go-top">
                                        <ul>
                                            <li><Link to="/contact">Asesoria</Link></li>
                                            {/*<li><Link to="/">Guia de compra</Link></li>*/}
                                            {/*<li><Link to="/">Tienda</Link></li>*/}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-md-6 col-sm-12 col-12">
                                <div className="footer-widget footer-newsletter-widget">
                                    <h5 className="mt-30">Medios de pago</h5>
                                    <img src={publicUrl + "assets/img/personal/payment.png"} alt="Payment Imagen"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Copyright/>
            </footer>
        )
    }
}


export default Footer_v1