import React from 'react';
import {FaBath, FaBed, FaHeart, FaMapMarkerAlt, FaRulerCombined} from 'react-icons/fa';
import {useHistory} from "react-router-dom/cjs/react-router-dom";

const PropertyCard = ({property}) => {
    const history = useHistory();

    const handleCardClick = () => {
        history.push(`/property-details/${property.PropertyID}`);
    };


    const bathrooms = property.Bedrooms != null;
    const bedrooms = property.Bathrooms != null;
    const price = property.Price != null;
    const meters = property.meters1 != null;

    return (
        <div className="card property-card shadow-sm" onClick={handleCardClick}>
            <img src={property.profileImg} alt="Imagen de propiedad" className="card-img-top"/>
            <div className="card-body">
                <span className={`badge ${property.Status.replace(/\s/g, '-').toLowerCase()}`}>
                {property.Status}</span>
                <h5 className="card-title">{property.propertyName}</h5>
                <p className="card-text"><FaMapMarkerAlt/> {property.City}, {property.Country}</p>

                <div>
                    <div className="features d-flex justify-content-between">
                        {bedrooms && (
                            <span><FaBed/> {property.Bedrooms}</span>
                        )}
                        {bathrooms && (
                            <span><FaBath/> {property.Bathrooms}</span>
                        )}
                        {meters && (
                            <span><FaRulerCombined/> {property.meters1}</span>
                        )}
                    </div>
                    <div className="price mt-3">
                        {price && (
                            <span className="font-weight-bold text-danger">${property.Price}</span>
                        )}
                    </div>
                </div>
            </div>
            <div className="card-footer bg-white d-flex justify-content-between">
                <small className="text-muted">{property.PropertyType}</small>
                <FaHeart className="text-muted"/>
            </div>
        </div>
    );
};

export default PropertyCard;
