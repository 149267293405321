import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import PropertyCard from './PropertyCard';
import propertiesData from "../utils/propertiesData";

const PropertyGrid = () => {
    const [filteredProperties, setFilteredProperties] = useState([]);
    const location = useLocation();

    const properties = propertiesData;

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const type = searchParams.get('type');
        let filtered;

        const typeMapping = {
            casas: 'Casa',
            apartamentos: 'Apartamento',
            terrenos: 'Terreno',
            hoteles: 'Hotel',
            villas: 'Villa'
        };

        if (type && typeMapping[type]) {
            filtered = properties.filter(property => property.PropertyType === typeMapping[type]);
        } else {
            filtered = properties;
        }

        setFilteredProperties(filtered);
    }, [location]);

    if (filteredProperties.length === 0) {
        return <h1>No se han encontrado datos</h1>;
    }

    return (
        <div className="grid-container">
            {filteredProperties.map(property => (
                <PropertyCard key={property.PropertyID} property={property}/>
            ))}
        </div>
    );
};

export default PropertyGrid;
